import { createTheme, responsiveFontSizes } from "@mui/material";
import { COLORS } from "./components";

var AppTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
    selected: {
      main: COLORS.selected,
      secondary: COLORS.selectedSecondary,
    },
    custom: {
      main: COLORS.skyBlue,
    },
    blank: {
      main: COLORS.white,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "cancel" },
          style: {
            textTransform: "none",
            color: COLORS.black,
            "&:hover": {
              backgroundColor: COLORS.mildAsh,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: "20px",
          textTransform: "none",
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "skyBlue" },
          style: {
            backgroundColor: COLORS.lightBlue,
            color: COLORS.white,
          },
        },
      ],
    },
  },
});

AppTheme = responsiveFontSizes(AppTheme);

export { AppTheme };
