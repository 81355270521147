import { Typography, Card } from "@mui/material";
import styled from "styled-components";
// import { COLORS } from "../utills/";

export const Typo = styled(Typography)`
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  z-index: ${(props) => props.zIndex};
  text-align: ${(props) => props.textAlign};
  font-weight: ${(props) => props.fontWeight};
  font-style: "poppins !important";
`;
