import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROOT_PATH, UNIQUE_URL } from "./RoutePath";
import LoginDashboard from "../mainLayout/Index";

function MainRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROOT_PATH} element={<LoginDashboard />}></Route>
        <Route path={UNIQUE_URL} element={<LoginDashboard />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoute;
