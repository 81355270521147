import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import CustomButton from '../components/CustomButton';
import { useStyles } from '../assets/css/Styles';
import Grid from '@mui/material/Grid';
import CustomPopup from '../components/CustomPopup';
import { Typo } from '../styled/Index';
import TextBox from '../components/TextBox';
import Logo from '../assets/images/LogoWithText.jpg';
import { isEmail, isEmptyString } from '../utills/Validation';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GETTER, SETTER } from '../utills/Constant';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NAVIGATE_TO, ROOT_PATH } from '../routes/RoutePath';
import {
  generateOtpAction,
  validateOtpAction,
  registerUser,
  validateUserAction,
  generateOtpToEmail,
} from '../redux/login/Action';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedSnackBars from '../components/CustomisedSnackBar';
import { ASSESSMENT_URL, COOKIE_DOMAIN } from '../axios/Url';
import Cookies from 'js-cookie';
import { checkForValidToken, getTestDataByUniqueUrl } from '../api/AsyncApi';
export const alphabetRegex = /^(?!\s)[A-Za-z\s]+$/;

export default function Index() {
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { uniqueId } = useParams();
  const type = query.get('type');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [fullNameErr, setFullNameErr] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileErr, setMobileErr] = useState('');
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [OtpOpen, setOtpOpen] = useState(false);
  const [flag, setFlag] = useState('');
  const [resendTime, setResendTime] = useState(0);
  const [resendFlag, setResendFlag] = useState(false);
  const [resendEmailFlag, setResendEmailFlag] = useState(false);
  const [otp1, setOtp1] = useState('');
  const [otp2, setOtp2] = useState('');
  const [otp3, setOtp3] = useState('');
  const [otp4, setOtp4] = useState('');
  const [otp1Err, setOtp1Err] = useState(false);
  const [otp2Err, setOtp2Err] = useState(false);
  const [otp3Err, setOtp3Err] = useState(false);
  const [otp4Err, setOtp4Err] = useState(false);
  const [state, setState] = useState({
    snackMsg: '',
    snackOpen: false,
    snackVariant: '',
  });

  const findPlatform = () => {
    if (uniqueId) {
      if (uniqueId.indexOf('aet') !== -1) {
        return 'ASSESSMENTENGINE';
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const pad = (d) => {
    return d < 10 ? '0' + d.toString() : d.toString();
  };
  const handleVerify = () => {
    isEmptyString(fullName) ? setFullNameErr('Please fill the required field') : setFullNameErr('');
    isEmptyString(mobileNumber)
      ? setMobileErr('Please fill the required field')
      : mobileNumber && mobileNumber.length !== 10
      ? setMobileErr('Please enter a valid Mobile Number')
      : setMobileErr('');
    // isEmptyString(email)
    // ? setEmailErr("Please fill the required field")
    // :isEmail(email)?setEmailErr(""):
    //   // setEmailErr("Enter valid E-Mail");
    if (email) {
      if (isEmail(email)) {
        setEmailErr('');
      } else {
        setEmailErr('Enter valid E-Mail');
      }
    } else {
      setEmailErr('Please fill the required field');
    }
    if (fullName && mobileNumber && mobileNumber.length === 10 && email && isEmail(email)) {
      let obj = {
        fullName: fullName,
        phoneNumber: mobileNumber,
        emailId: email,
      };
      setTimeout(() => {
        dispatch(validateUserAction(obj, findPlatform()));
      }, 3000);
      // dispatch(registerUser(obj));
    }
  };

  const handleAuthClick = (type, key) => {
    setFullName('');
    setMobileNumber('');
    setFlag(type);
    setOpen(true);
    if (uniqueId) {
      setTimeout(() => {
        navigate(NAVIGATE_TO.withUniqueId(uniqueId, key));
      }, 3000);
    } else {
      setTimeout(() => {
        navigate(NAVIGATE_TO.loginPath(key));
      }, 3000);
    }
  };

  const handleAccountClick = () => {
    if (flag === 'Login') {
      if (uniqueId) {
        setTimeout(() => {
          navigate(NAVIGATE_TO.withUniqueId(uniqueId, 'signup'));
        }, 3000);
      } else {
        navigate(NAVIGATE_TO.loginPath('signup'));
      }
      setFlag('Signup');
      // setEmailErr("");
    } else {
      setFullName('');
      setFlag('Login');
      if (uniqueId) {
        setTimeout(() => {
          navigate(NAVIGATE_TO.withUniqueId(uniqueId, 'login'));
        }, 3000);
      } else {
        setTimeout(() => {
          navigate(NAVIGATE_TO.loginPath('login'));
        }, 3000);
      }
    }
  };
  const handleLoginWithOTP = () => {
    isEmptyString(mobileNumber)
      ? setMobileErr('Please fill the required field')
      : mobileNumber && mobileNumber.length !== 10
      ? setMobileErr('Please enter a valid Mobile Number')
      : setMobileErr('');

    if (mobileNumber && mobileNumber.length === 10) {
      dispatch(generateOtpAction(mobileNumber, findPlatform()));
    }
  };

  const { validateOtp, generateOtp, postSignup, validateUser } = useSelector(
    (state) => state.LoginReducer
  );
  useEffect(() => {
    if (generateOtp) {
      if (generateOtp.success) {
        if (generateOtp.data.userType !== 'Not Registered') {
          setState({
            snackMsg: generateOtp.data.message,
            snackOpen: true,
            snackVariant: 'success',
          });
          setOpen(false);
          setOtpOpen(true);
        } else {
          if (fullName.length !== 0) {
            setOtpOpen(true);
            setOpen(false);
            setFlag('Signup');

            if (uniqueId) {
              setTimeout(() => {
                navigate(NAVIGATE_TO.withUniqueId(uniqueId, 'signup'));
              }, 3000);
            } else {
              setTimeout(() => {
                navigate(NAVIGATE_TO.loginPath('signup'));
              }, 3000);
            }
          } else {
            setState({
              snackMsg: 'Kindly register the user before login',
              snackOpen: true,
              snackVariant: 'error',
            });
            setOpen(true);
            setFlag('Signup');
            if (uniqueId) {
              setTimeout(() => {
                navigate(NAVIGATE_TO.withUniqueId(uniqueId, 'signup'));
              }, 3000);
            } else {
              setTimeout(() => {
                navigate(NAVIGATE_TO.loginPath('signup'));
              }, 3000);
            }
          }
        }
      } else {
        setState({
          snackMsg: generateOtp.message,
          snackOpen: 'true',
          snackVariant: 'error',
        });
      }
    }
  }, [generateOtp]);

  useEffect(() => {
    if (validateUser?.success) {
      if (validateUser?.data === 'credentials verification Success') {
        dispatch(generateOtpAction(mobileNumber, findPlatform()));
      } else {
        setState({
          snackMsg: validateUser?.data?.message,
          snackOpen: 'true',
          snackVariant: 'error',
        });
      }
    }
  }, [validateUser]);

  useEffect(() => {
    if (validateOtp) {
      if (validateOtp.success) {
        setState({
          snackMsg: validateOtp.data.message,
          snackOpen: 'true',
          snackVariant:
            validateOtp.data.message !== 'Invalid OTP' || validateOtp.data.message !== 'Invalid Otp'
              ? 'success'
              : 'error',
        });

        if (
          validateOtp.data.message === 'Invalid OTP' ||
          validateOtp.data.message === 'Invalid Otp'
        ) {
          setState({
            snackMsg: 'Invalid OTP',
            snackOpen: 'true',
            snackVariant: 'error',
            OtpOpen: true,
          });
        } else if (
          validateOtp.data.message !== 'Invalid OTP' ||
          validateOtp.data.message !== 'Invalid Otp'
        ) {
          SETTER.cookieSetter('studentId', validateOtp.data.id, {
            domain: COOKIE_DOMAIN,
            path: '',
          });
          SETTER.cookieSetter('accessToken', validateOtp.data.token.accessToken, {
            domain: COOKIE_DOMAIN,
            path: '',
          });
          SETTER.cookieSetter('refreshToken', validateOtp.data.token.refreshToken, {
            domain: COOKIE_DOMAIN,
            path: '',
          });

          setOtpOpen(false);
          setResendFlag(false);
          setResendTime(0);

          if (type === 'login') {
            if (
              uniqueId !== 'landing' &&
              (uniqueId !== '' || uniqueId !== null || uniqueId !== undefined)
            ) {
              getTestDataByUniqueUrl(
                uniqueId,
                validateOtp.data.token.accessToken,
                validateOtp.data.id
              ).then((response) => {
                console.log(response);
                if (response.status === 200) {
                  if (response.data.message === 'You already took the test') {
                    setState({
                      snackMsg: 'Test Completed',
                      snackOpen: 'true',
                      snackVariant: 'error',
                    });
                    setTimeout(() => {
                      window.location.href = ASSESSMENT_URL;
                    }, 1000);
                  } else if (response.data.data) {
                    SETTER.cookieSetter('testQuestionSetId', response.data.data, {
                      domain: COOKIE_DOMAIN,
                      path: '',
                    });
                    setTimeout(() => {
                      window.location.href = ASSESSMENT_URL;
                    }, 1000);
                  }
                }
              });
            } else {
              setTimeout(() => {
                window.location.href = GETTER.cookieGetter('redirectUrl');
              }, 1000);
            }
          }

          if (generateOtp.data.userType === 'Not Registered') {
            let obj = {
              fullName: fullName,
              phoneNumber: mobileNumber,
              emailId: email,
            };
            setTimeout(() => {
              dispatch(registerUser(obj, findPlatform()));
            }, 1000);
          }
        }
      } else {
        setState({
          snackMsg: validateOtp.message,
          snackOpen: 'true',
          snackVariant: 'error',
        });
      }
    }
  }, [validateOtp]);

  useEffect(() => {
    if (postSignup) {
      if (postSignup.success) {
        if (postSignup.data.message === 'Email Id Already Exists') {
          setState({
            snackMsg: postSignup.data.message,
            snackOpen: 'true',
            snackVariant: 'error',
          });
          setOpen(false);
          setOtpOpen(true);
        } else if (postSignup.data.userType === 'Registered') {
          SETTER.cookieSetter('studentId', postSignup.data.id, {
            domain: COOKIE_DOMAIN,
            path: '',
          });
          SETTER.cookieSetter('accessToken', postSignup.data.token.accessToken, {
            domain: COOKIE_DOMAIN,
            path: '',
          });
          SETTER.cookieSetter('refreshToken', postSignup.data.token.refreshToken, {
            domain: COOKIE_DOMAIN,
            path: '',
          });
          setState({
            snackMsg: postSignup.data.message,
            snackOpen: 'true',
            snackVariant: 'success',
          });
          setOpen(false);
          if (type === 'signup') {
            setOtpOpen(false);
            if (
              uniqueId !== 'landing' &&
              (uniqueId !== '' || uniqueId !== null || uniqueId !== undefined)
            ) {
              getTestDataByUniqueUrl(
                uniqueId,
                postSignup.data.token.accessToken,
                postSignup.data.id
              ).then((response) => {
                console.log(response);
                if (response.status === 200) {
                  if (response.data.message === 'You already took the test') {
                    setState({
                      snackMsg: 'Test Completed',
                      snackOpen: 'true',
                      snackVariant: 'error',
                    });
                    setTimeout(() => {
                      window.location.href = ASSESSMENT_URL;
                    }, 1000);
                  } else if (response.data.data) {
                    SETTER.cookieSetter('testQuestionSetId', response.data.data, {
                      domain: COOKIE_DOMAIN,
                      path: '',
                    });
                    setTimeout(() => {
                      window.location.href = ASSESSMENT_URL;
                    }, 1000);
                  }
                }
              });
            } else {
              setTimeout(() => {
                window.location.href = GETTER.cookieGetter('redirectUrl');
              }, 1000);
            }
          } else {
            setOtpOpen(true);
          }
        } else {
          setOpen(false);
          setOtpOpen(true);
        }
      } else {
        setState({
          snackMsg: postSignup.message,
          snackOpen: 'true',
          snackVariant: 'error',
        });
        setOpen(false);
        setOtpOpen(true);
      }
    }
  }, [postSignup]);
  const handleSubmit = () => {
    isEmptyString(otp1) ? setOtp1Err(true) : setOtp1Err(false);
    isEmptyString(otp2) ? setOtp2Err(true) : setOtp2Err(false);
    isEmptyString(otp3) ? setOtp3Err(true) : setOtp3Err(false);
    isEmptyString(otp4) ? setOtp4Err(true) : setOtp4Err(false);

    if (otp1 !== '' && otp2 !== '' && otp3 !== '' && otp4 !== '') {
      let obj = {
        phoneNumber: mobileNumber,
        otp: otp1 + otp2 + otp3 + otp4,
      };
      setTimeout(() => {
        dispatch(validateOtpAction(obj, findPlatform()));
      }, 1000);
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: 'Please fill all the fields',
        snackVariant: 'error',
      });
    }
  };
  const handleBack = () => {
    setOpen(true);
    setOtpOpen(false);
    setResendFlag(false);
    setResendTime(0);
    setFlag('Login');
  };

  //Resend OTP click
  const resendClick = () => {
    setOtp1('');
    setOtp2('');
    setOtp3('');
    setOtp4('');
    setResendFlag(true);
    setResendTime(60);
    dispatch(generateOtpAction(mobileNumber, findPlatform()));
  };

  //Resend OTP to Email
  const resendOtpToEmail = () => {
    let payload = {
      phoneNumber: mobileNumber,
      emailId: email,
    };
    dispatch(
      generateOtpToEmail(payload, findPlatform(), (response) => {
        if (response?.data?.message === 'OTP Send Successfully to given mailId') {
          setState({
            snackMsg: 'OTP Sent Successfully to given Email Id',
            snackOpen: 'true',
            snackVariant: 'success',
          });
        } else {
          setState({
            snackMsg: 'Something Went Wrong!!',
            snackOpen: 'true',
            snackVariant: 'error',
          });
        }
      })
    );
  };

  useEffect(() => {
    setFullNameErr('');
    setMobileErr('');
    setEmailErr('');
    // setFullName("");
  }, [flag]);

  useEffect(() => {
    setOtp1('');
    setOtp2('');
    setOtp3('');
    setOtp4('');
    setOtp1Err('');
    setOtp2Err('');
    setOtp3Err('');
    setOtp4Err('');
  }, [OtpOpen]);

  useEffect(() => {
    if (resendTime !== 0) {
      setTimeout(() => {
        setResendTime(resendTime - 1);
      }, 1000);
    } else {
      setResendFlag(false);
    }
  }, [resendTime]);
  useEffect(() => {
    if (type === 'login') {
      setOpen(true);
      setFlag('Login');
    } else {
      setOpen(true);
      setFlag('Signup');
    }
    setState({
      snackMsg: '',
      snackOpen: false,
      snackVariant: '',
    });
    if (!type) {
      if (uniqueId) {
        setTimeout(() => {
          navigate(NAVIGATE_TO.withUniqueId(uniqueId, 'signup'));
        }, 3000);
      } else {
        setTimeout(() => {
          navigate(NAVIGATE_TO.loginPath('signup'));
        }, 3000);
      }
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setOtpOpen(false);
    setResendFlag(false);
    setResendTime(0);
    setMobileNumber('');
    setFullName('');
    setFullNameErr('');
    setMobileErr('');
    setEmail('');
    setEmailErr('');
  };
  const handleSnackClose = () => {
    setState({ snackOpen: false, snackMsg: '', snackVariant: '' });
  };

  return (
    <div style={{ height: '100%' }}>
      <Grid container>
        {/* app bar */}
        <Grid item md={12}>
          <AppBar position='fixed' className={classes.appBarStyle}>
            <Toolbar>
              <div className={classes.toolbarDivStyle}>
                <img src={Logo} alt={'cl_image'} className={classes.logoStyle} />
              </div>
              <div className={classes.appbarButtonStyle}>
                <CustomButton
                  className={classes.root}
                  variant={'outlined'}
                  color={'primary'}
                  children={'Login'}
                  onClick={() => handleAuthClick('Login', 'login')}
                />
                <CustomButton
                  className={classes.root}
                  variant={'contained'}
                  color={'primary'}
                  children={'Signup'}
                  onClick={() => handleAuthClick('Signup', 'signup')}
                />
              </div>
            </Toolbar>
          </AppBar>
        </Grid>
        {/* --------------- */}
        <CustomPopup open={open} maxWidth={'xs'}>
          <div className={classes.popupMainDiv}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align='center'>
                <img src={Logo} alt={'cl_image'} className={classes.popupImage} />
              </Grid>
              <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align='center'>
                <Typo fontWeight='bold' fontSize={'28px'} className={classes.popuptitle}>
                  Welcome to CareerLabs
                </Typo>
              </Grid>
              {flag === 'Signup' ? (
                <>
                  <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align='center'>
                    <TextBox
                      className={classes.textBoxStyle}
                      label={'Full Name'}
                      id='fullName'
                      name='fullName'
                      value={fullName}
                      type='text'
                      onChange={(e) => {
                        if (
                          alphabetRegex.test(e.target.value) ||
                          e.target.value === null ||
                          e.target.value === ''
                        ) {
                          setFullName(e.target.value);
                        } else {
                          e.preventdefault();
                        }
                        if (e.target.value) {
                          setFullNameErr('');
                        }
                      }}
                      error={fullNameErr}
                      helperText={fullNameErr}
                      size={'small'}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} align='center'>
                    <TextBox
                      className={classes.textBoxStyle}
                      label={'E-Mail'}
                      id='email'
                      name='email'
                      value={email}
                      type='text'
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailErr('');
                      }}
                      error={emailErr}
                      helperText={emailErr}
                      size={'small'}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align='center'>
                    <TextBox
                      className={classes.textBoxStyle}
                      label={'Mobile Number'}
                      id='mobileNumber'
                      name='mobileNumber'
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value.replace(/[^0-9]/g, ''));
                        setMobileErr('');
                      }}
                      error={mobileErr}
                      helperText={mobileErr}
                      size={'small'}
                      inputProps={{ maxLength: 10 }}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align='center'>
                    <CustomButton
                      color={'primary'}
                      variant={'contained'}
                      className={classes.buttonStyle}
                      onClick={() => handleVerify()}
                    >
                      Sign Up
                    </CustomButton>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} align='center'>
                    <TextBox
                      className={classes.textBoxStyle}
                      label={'Mobile Number'}
                      id='mobileNumber'
                      name='mobileNumber'
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value.replace(/[^0-9]/g, ''));
                        setMobileErr('');
                      }}
                      error={mobileErr}
                      helperText={mobileErr}
                      size={'small'}
                      inputProps={{ maxLength: 10 }}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align='center'>
                    <CustomButton
                      color={'primary'}
                      variant={'contained'}
                      className={classes.buttonStyle}
                      onClick={() => handleLoginWithOTP()}
                    >
                      Login with OTP
                    </CustomButton>
                  </Grid>
                </>
              )}
              <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
                <div className={classes.flexydiv}>
                  <Typo fontSize={'12px'} color='gray'>
                    {flag === 'Signup' ? 'Already have a account ?' : "Don't have an account ?"}
                  </Typo>
                  <Typo
                    fontSize={'12px'}
                    className={classes.newOpenStyle}
                    onClick={() => handleAccountClick()}
                  >
                    {flag === 'Signup' ? 'Log in' : 'Sign up'}
                  </Typo>
                </div>
              </Grid>
              <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
                <div className={classes.flexydiv}>
                  <Typo color='gray' fontSize={'12px'}>
                    By continuing, you agree to CareerLabs
                  </Typo>
                  <Typo fontSize={'12px'} className={classes.newOpenStyle}>
                    Terms of Service
                  </Typo>
                </div>
                <div className={classes.flexydiv}>
                  <Typo fontSize={'12px'} color='gray'>
                    and acknowledge you've read our
                  </Typo>
                  <Typo fontSize={'12px'} className={classes.newOpenStyle}>
                    {'Privacy Policy'}
                  </Typo>
                </div>
              </Grid>
            </Grid>
            <div className={classes.closeDiv}>
              <Typo
                fontSize={'10px'}
                color={'blue'}
                onClick={() => handleClose()}
                className={classes.cursorStyle}
              >
                Close
              </Typo>
            </div>
          </div>
        </CustomPopup>
        <CustomPopup open={OtpOpen} maxWidth={'xs'}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'right'}>
              <Typo
                fontSize={'10px'}
                color={'blue'}
                onClick={() => {
                  setOtpOpen(false);
                  setResendFlag(false);
                  setResendTime(0);
                  setOpen(false);
                }}
                className={classes.cursorStyle}
              >
                Close
              </Typo>
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
              <Typo fontWeight={'bold'} fontSize={'27px'}>
                Verification
              </Typo>
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
              <Typo fontSize={'15px'}>Sent verification code to verify your mobile number</Typo>
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
              <div className={classes.flexydiv}>
                <Typo fontSize={'15px'} color='gray'>
                  Sent to
                </Typo>
                <Typo className={classes.numberStyle} fontSize={'15px'}>
                  {mobileNumber}
                </Typo>
              </div>
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
              {/* <div className={classes.otpDiv}>
              <OTPInput
                // value={otp}
                // onChange={handleChange}
                numInputs={4}
                otpType="number"
                id={"otpInput-btn"}
              />
            </div> */}
              <Grid container>
                <Grid item md={3} xs={3} sm={3} lg={3} xl={3}>
                  <TextBox
                    value={otp1}
                    id={'one'}
                    onChange={(e) => {
                      const { value } = e.target;
                      setOtp1(value.replace(/[^0-9]/g, ''));
                      if (value.replace(/[^0-9]/g, '')) {
                        let secondBox = document.getElementById('two');
                        secondBox.focus();
                      }
                    }}
                    className={classes.otpTextBox}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center' },
                    }}
                    error={otp1Err}
                  />
                </Grid>
                <Grid item md={3} xs={3} sm={3} lg={3} xl={3}>
                  <TextBox
                    value={otp2}
                    id={'two'}
                    onChange={(e) => {
                      const { value } = e.target;
                      setOtp2(value.replace(/[^0-9]/g, ''));
                      if (value) {
                        if (value.replace(/[^0-9]/g, '')) {
                          let thirdBox = document.getElementById('three');
                          thirdBox.focus();
                        }
                      } else {
                        let firstBox = document.getElementById('one');
                        firstBox.focus();
                      }
                    }}
                    className={classes.otpTextBox}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center' },
                    }}
                    error={otp2Err}
                  />
                </Grid>
                <Grid item md={3} xs={3} sm={3} lg={3} xl={3}>
                  <TextBox
                    value={otp3}
                    id={'three'}
                    onChange={(e) => {
                      const { value } = e.target;
                      setOtp3(value.replace(/[^0-9]/g, ''));
                      if (value) {
                        if (value.replace(/[^0-9]/g, '')) {
                          let fourthBox = document.getElementById('four');
                          fourthBox.focus();
                        }
                      } else {
                        let SecondBox = document.getElementById('two');
                        SecondBox.focus();
                      }
                    }}
                    className={classes.otpTextBox}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center' },
                    }}
                    error={otp3Err}
                  />
                </Grid>
                <Grid item md={3} xs={3} sm={3} lg={3} xl={3}>
                  <TextBox
                    value={otp4}
                    id={'four'}
                    onChange={(e) => {
                      const { value } = e.target;
                      setOtp4(e.target.value.replace(/[^0-9]/g, ''));
                      if (!value) {
                        let thirdBox = document.getElementById('three');
                        thirdBox.focus();
                      }
                    }}
                    className={classes.otpTextBox}
                    error={otp4Err}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center' },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
              <div className={classes.flexydiv}>
                <Typo variant='subtitle2' color='gray'>
                  Didn't recieve OTP ?
                </Typo>
                <div className={classes.resendStyle}>
                  <Typo
                    fontSize={'10px'}
                    color='red'
                    fontWeight={'bold'}
                    className={classes.cursorStyle}
                    onClick={() => (!resendFlag ? resendClick() : '')}
                  >
                    {resendFlag ? ` 00 : ${pad(resendTime)} secs` : 'RESEND OTP'}
                  </Typo>
                  <Typo color='gray' style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {'•'}
                  </Typo>
                  <Typo
                    fontSize={'10px'}
                    color='red'
                    fontWeight={'bold'}
                    className={classes.cursorStyle}
                    onClick={() => resendOtpToEmail()}
                  >
                    {'RESEND OTP TO EMAIL'}
                  </Typo>
                </div>
              </div>
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
              <CustomButton
                color={'primary'}
                variant={'contained'}
                className={classes.buttonStyle}
                onClick={() => handleSubmit()}
              >
                Submit
              </CustomButton>
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12} align={'center'}>
              <div className={classes.flexydiv}>
                <ChevronLeftIcon
                  fontSize='small'
                  onClick={() => handleBack()}
                  className={classes.cursorStyle}
                />
                <Typo
                  variant='subtitle2'
                  color='gray'
                  className={classes.backStyle}
                  onClick={() => handleBack()}
                >
                  {'Back to Login'}
                </Typo>
              </div>
            </Grid>
          </Grid>
        </CustomPopup>
      </Grid>
      <CustomizedSnackBars
        open={state.snackOpen}
        severity={state.snackVariant}
        message={state.snackMsg}
        onClose={handleSnackClose}
      />
    </div>
  );
}
