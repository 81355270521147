import Cookies from "js-cookie";

export const SETTER = {
  sessionSetter: (name, value) => sessionStorage.setItem(name, value),
  cookieSetter: (name, value, options) => Cookies.set(name, value, options),
};

export const GETTER = {
  sessionGetter: (name) => sessionStorage.getItem(name),
  cookieGetter: (name) => Cookies.get(name),
};
