export const COLORS = {
  primary: "#343B89",
  secondary: "#81C14F",
  selected: "#FFD500",
  white: "#ffffff",
  purple: "#343B89",
  lightAsh: "#C8C8C8",
  darkNavy: "#172B4D",
  black: "#000000",
  darkAsh: "#999999",
  selectedSecondary: "#F5DE41",
  naveBlue: "#1093FF",
  skyBlue: "#00AEEF",
  darkCharcoal: "#333333",
  lightBlack: "#666666",
  green: "#81c14f",
  red: "#f2463b",
  yellow: "#efac2",
  lightBlack: "#666666",
  mildAsh: "#e7e7e7",
  lightBlue: "#18AAE7",
  darkBlue: "#3c56fd",
  red: "#FD413C",
  lightRed: "#FDECEB",
};
