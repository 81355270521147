import { Login } from './ActionType';

const INITIAL_STATE = {
  validateOtp: null,
  generateOtp: null,
  generateOtpToEmail: null,
  postSignup: null,
  validateUser: null,
};

const LoginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Login.validateOtp:
      return {
        ...state,
        validateOtp: action.payload,
      };
    case Login.generateOtp:
      return {
        ...state,
        generateOtp: action.payload,
      };
    case Login.generateOtpToEmail:
      return {
        ...state,
        generateOtpToEmail: action.payload,
      };
    case Login.postSignup:
      return {
        ...state,
        postSignup: action.payload,
      };
    case Login.validateUser:
      return {
        ...state,
        validateUser: action.payload,
      };

    default:
      return state;
  }
};

export default LoginReducer;
