import axios from "axios";
import { BASE_URL, LOGIN_URL, RE_API_URL } from "../axios/Url";

const checkForValidToken = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL + LOGIN_URL.checkValidToken()}`,
      {
        headers: {
          jwt: "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5NDg3OTgwMDA5IiwiZXhwIjoxNjUyMjc4MjkzLCJpYXQiOjE2NTIyNzEwOTN9.gMZf4HUf9EG6YURftvBi667_-rKl1NoInCGInpfKR0Et5bY_g477b9fZD1rz90z7O1SMZPjadOSMWMBi_WyqRA",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

const getTestDataByUniqueUrl = async (uniqueId, accessToken,studentId) => {
  try {
    const response = axios.get(
      `${BASE_URL + LOGIN_URL.getTestDataByUniqueId(uniqueId,studentId)}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export { checkForValidToken, getTestDataByUniqueUrl };
