export const BASE_URL = process.env.REACT_APP_API_URL;
export const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const CV_BUILDER_URL = process.env.REACT_APP_CV_BUILDER_URL;
export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
export const ASSESSMENT_URL = process.env.REACT_APP_ASSESSMENT_URL;
export const RE_API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = {
  generateOtpUrl: (number) => `/api/v1/students/generateOtp/` + number,
  generateOtpToEmailUrl: () => `/api/v1/students/otp/resend`,
  validateOtpUrl: () => `/api/v1/students/otpValidate`,
  postSignupUrl: () => `/api/v1/students/registerAfterVerification`,
  checkValidToken: () => `/api/v1/token/status`,
  getTestDataByUniqueId: (uniqueId, studentId) =>
    `/api/v2/lms/url/${uniqueId}/student/${studentId}`,
  validateUserUrl: () => `/api/v1/students/validateregistration`,
};
