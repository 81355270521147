import axios from 'axios';
import { Login } from './ActionType';
import { BASE_URL, LOGIN_URL } from '../../axios/Url';

import { errorHandler } from '../../utills/HelperFunction';

const URL = `${BASE_URL}/api/v1`;

export const generateOtpAction = (number, platform = 'CVBUILDER') => {
  return async (dispatch) => {
    try {
      dispatch({ type: Login.generateOtp });
      await axios
        .put(
          BASE_URL + LOGIN_URL.generateOtpUrl(number),
          {},
          {
            headers: {
              platform: platform,
            },
          }
        )
        .then((result) => {
          dispatch({
            type: Login.generateOtp,
            payload: { ...result, success: true },
          });
        });
    } catch (error) {
      dispatch(errorHandler(Login.generateOtp, error, false));
    }
  };
};

// Generate OTP To Email
export const generateOtpToEmail = (data, platform = 'CVBUILDER', callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Login.generateOtpToEmail });
      await axios
        .put(BASE_URL + LOGIN_URL.generateOtpToEmailUrl(), data, {
          crossDomain: true,
          headers: {
            platform: platform,
          },
        })
        .then((result) => {
          dispatch({
            type: Login.generateOtpToEmail,
            payload: { ...result, success: true },
          });
          callback(result);
        });
    } catch (error) {
      dispatch(errorHandler(Login.generateOtpToEmail, error, false));
    }
  };
};

export const validateOtpAction = (data, platform = 'CVBUILDER') => {
  return async (dispatch) => {
    try {
      dispatch({ type: Login.validateOtp });
      await axios
        .put(BASE_URL + LOGIN_URL.validateOtpUrl(), data, {
          crossDomain: true,
          headers: {
            platform: platform,
          },
        })
        .then((result) => {
          dispatch({
            type: Login.validateOtp,
            payload: { ...result, success: true },
          });
        });
    } catch (error) {
      dispatch(errorHandler(Login.validateOtp, error, false));
    }
  };
};

export const registerUser = (data, platform = 'CVBUILDER') => {
  return async (dispatch) => {
    try {
      dispatch({ type: Login.postSignup });
      await axios
        .post(BASE_URL + LOGIN_URL.postSignupUrl(), data, {
          crossDomain: true,
          headers: {
            platform: platform,
          },
        })
        .then((result) => {
          dispatch({
            type: Login.postSignup,
            payload: { ...result, success: true },
          });
        });
    } catch (error) {
      dispatch(errorHandler(Login.postSignup, error, false));
    }
  };
};

export const validateUserAction = (obj, platform = 'CVBUILDER') => {
  return async (dispatch) => {
    try {
      dispatch({ type: Login.validateUser });
      await axios
        .post(BASE_URL + LOGIN_URL.validateUserUrl(), obj, {
          headers: {
            platform: platform,
          },
        })
        .then((result) => {
          dispatch({
            type: Login.validateUser,
            payload: { ...result, success: true },
          });
        });
    } catch (error) {
      dispatch(errorHandler(Login.validateUser, error, false));
    }
  };
};
