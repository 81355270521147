import React from "react";
import { Dialog, DialogContent } from "@mui/material";
function CustomPopup({ open, onClose, children, maxWidth }) {
  return (
      <Dialog open={open} onClose={onClose} maxWidth={maxWidth}>
        <DialogContent>{children}</DialogContent>
      </Dialog>
  );
}

export default CustomPopup;
