import { TextField } from "@mui/material";
import React from "react";

function TextBox({
  value,
  color,
  variant,
  disabled,
  onChange,
  label,
  name,
  error,
  helperText,
  InputProps,
  inputProps,
  placeholder,
  type,
  ...rest
}) {
  return (
    <TextField
      color={color}
      variant={variant}
      value={value}
      onChange={onChange}
      disabled={disabled}
      label={label}
      type={type}
      name={name}
      error={error}
      helperText={helperText}
      inputProps={inputProps}
      InputProps={InputProps}
      placeholder={placeholder}
      {...rest}
    />
  );
}

export default TextBox;
