import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "20px !important",
    backgroundColor: "red",
  },
  popuptitle: {
    letterSpacing: "1.22px",
  },
  flexydiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  textBoxStyle: {
    width: "60%",
  },
  buttonStyle: {
    width: "60%",
    textTransform: "none !important",
  },
  cursorStyle: {
    cursor: "pointer",
  },
  newOpenStyle: {
    cursor: "pointer",
    paddingLeft: "1%",
  },
  popupImage: {
    width: "200px",
  },

  resendStyle: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1%",
  },
  numberStyle: {
    paddingLeft: "1%",
  },
  otpDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backStyle: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  otpTextBox: {
    width: "60%",
  },
  appBarStyle: {
    backgroundColor: "white !important",
  },
  toolbarDivStyle: {
    flexGrow: "1",
  },
  logoStyle: {
    width: "180px",
  },
  appbarButtonStyle: {
    display: "flex",
    justifyContent: "space-around",
    width: "200px",
  },
  popupMainDiv: {
    paddingTop: "15%",
    paddingBottom: "14%",
    position: "relative",
  },
  closeDiv: {
    position: "absolute",
    top: "1%",
    right: "1%",
  },
  dataCardDiv: {
    display: "flex",
    alignItems: "center",
    padding: "2%",
    width: "80%",
    border: "1px solid #D5D5D5",
    borderRadius: "10px",
  },
  activeDataCardDiv: {
    display: "flex",
    alignItems: "center",
    padding: "2%",
    width: "80%",
    border: "1px solid #1C237E",
    borderRadius: "10px",
  },
  rightContainerStyle: {
    display: "flex",
    paddingTop: "7%",
    paddingLeft: "5%",
  },
  dashboardImageStyle: {
    height: "80px",
  },
  dashboardImageGrid: {
    height: "100%",
  },
  cardMainDiv: {
    padding: "20px",
  },
}));
