import moment from "moment";

/**
 *
 * @param {String} type
 * @param {Object} error
 * @param {Boolean} loading
 * @returns Object
 */
export const dispatchError = (type, error, loading) => ({
  type: type,
  payload: {
    success: false,
    message: error.response && error.response.data.message,
  },
  loading: loading,
});

/**
 *
 * @param {String} type
 * @param {Object} error
 * @param {Boolean} loading
 * @returns Object
 */
export const errorHandler = (type, error, loading) => {
  var message = "Expectation Failed";
  if (
    error.response &&
    typeof error.response.data === "string" &&
    error.response.data.length !== 0
  )
    message = error.response.data;
  else if (
    error.response &&
    error.response.data.message &&
    error.response.data.message.length !== 0
  )
    message = error.response.data.message;
  return {
    type: type,
    payload: {
      success: false,
      message: message,
    },
    loading: loading,
  };
};

export const isNumber = (evt) => {
  return (
    (evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57
  );
};

export const isAllowNumber = (evt) => {
  const { value } = evt.target;
  var rgx = /^[0-9]*\.?[0-9]*$/;
  return value.match(rgx) && evt.which > 57;
};

export const isAlpha = (evt) => {
  var keyCode = evt.which ? evt.which : evt.keyCode;
  return (
    (keyCode < 65 || keyCode > 90) &&
    (keyCode < 97 || keyCode > 123) &&
    keyCode !== 32
  );
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) u8arr[n] = bstr.charCodeAt(n);
  return new File([u8arr], filename, { type: mime });
};

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export default async function getCroppedImg(imageSrc, pixelCrop, rotation) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // As Base64 string
  // return canvas.toDataURL("image/jpeg");
  return canvas;
}

export const generateDownload = async (imageSrc, crop, rotate) => {
  if (!crop || !imageSrc) {
    return;
  }
  const canvas = await getCroppedImg(imageSrc, crop, rotate);
  return canvas;
};

export const customDateFormat = (date, format) => {
  return date ? moment(new Date(date)).format(format) : "NA";
};
